import React, {useContext, useMemo} from 'react';
import {BREAKPOINTS} from '../../../../../utils/commonHelper';
import {ThreeColumnListing} from '../ThreeColumnListing';
import PropTypes from 'prop-types';
import {PortalConfigContext} from '../../../../../config/portal';
import {Ad} from '@dmm/react-common-components';
import {buildSponsoredListingProps} from '../../../../../utils/propsBuilder/listingPropsBuilder';
import {THREE_COL_FEATURED_BOATS} from '../../../../../utils/domParserHelper';

const SponsoredAd = ({sponsoredAdParams, id}) => {
  return (<div key={`mobile${id}`} className="sticky-mobile-ad">
    <Ad {...sponsoredAdParams} />
  </div>);
};
//  create Proptypes
SponsoredAd.propTypes = {
  sponsoredAdParams: PropTypes.object,
  id: PropTypes.number
};

export const createSponsoredBoats = (sponsoredBoats, context, position, dependencies) => {
  const {
    actions, makeDependencies, type, propFlags, priceProps, isSearchMobileBoxAds, adParams, enableSponsoredSearchExactMatch,
  } = dependencies;
  const {device} = makeDependencies;
  propFlags.isExactMatchSponsored = enableSponsoredSearchExactMatch;
  try {
    const sponsoredSectionElementProps = sponsoredBoats.reduce((listingArr, sponsoredBoat, index) => {
      position += 1;
      makeDependencies.position = position;
      makeDependencies.defaultType = type;
      const sponsoredListingProps = buildSponsoredListingProps(sponsoredBoat, context, actions, makeDependencies, propFlags, priceProps);
      sponsoredListingProps.targetBlank = propFlags.shouldOpenInNewTab;
      const sponsoredBoatProps = {key: sponsoredBoat.id, sponsoredListingProps, position};
      if (device === BREAKPOINTS.mobile) {
        if (!isSearchMobileBoxAds && index === 1) {
          listingArr.push({
            sponsoredAdParams: adParams.mobileLeaderboard1Params,
            id: sponsoredBoats.id
          });
        }
        listingArr.push(sponsoredBoatProps);
        return listingArr;
      }
      listingArr.push(sponsoredBoatProps);
      return listingArr;
    }, []);
    return sponsoredSectionElementProps;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error in createSponsoredBoats', e);
    return [];
  }
};

const SponsoredBoatListing = ({elementProps}) => {
  if (elementProps.sponsoredAdParams) {
    return <SponsoredAd {...elementProps} />;
  }

  return (
    <ThreeColumnListing key={elementProps.key} debug={true}
                        position={elementProps.position}
                        {...elementProps.sponsoredListingProps}/>
  );
};

SponsoredBoatListing.propTypes = {
  elementProps: PropTypes.object,
  i: PropTypes.number
};

const SponsoredBoatListings = ({
   sponsoredBoats, position, adParams, type,
   priceProps, propFlags, makeDependencies, actions
 }) => {
  const context = useContext(PortalConfigContext);
  const isSearchMobileBoxAds = !!context.supports?.isSearchMobileBoxAds;
  const enableSponsoredSearchExactMatch = !!context.supports?.enableSponsoredSearchExactMatch;

  if (sponsoredBoats?.length < 3) {
    return null;
  }

  const dependencies = {
    actions, makeDependencies, propFlags, priceProps, isSearchMobileBoxAds, adParams, enableSponsoredSearchExactMatch, type
  };
  const sponsoredBoatProps = useMemo(() => {
    return createSponsoredBoats(sponsoredBoats, context, position, dependencies);
  }, [sponsoredBoats, context, position, dependencies]);

  return (
    <>
      <div className="container sponsored listing-block-1" data-testid={THREE_COL_FEATURED_BOATS}>
        {sponsoredBoatProps.map((elementProps, i) => <SponsoredBoatListing key={i} elementProps={elementProps}/> )}
      </div>
    </>
  );

};

// create Proptypes
SponsoredBoatListings.propTypes = {
  sponsoredBoats: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  position: PropTypes.number,
  adParams: PropTypes.object,
  currency: PropTypes.string,
  tracking: PropTypes.object,
  priceProps: PropTypes.object,
  propFlags: PropTypes.object,
  makeDependencies: PropTypes.object,
  actions: PropTypes.object,
  type: PropTypes.string
};

export {SponsoredBoatListings};
