import React, {useContext, useEffect, useMemo, useState} from 'react';
import {getBoatUrl} from '../../../../../utils/urlHelpers/boat';
import {PortalConfigContext} from '../../../../../config/portal';

import {
  BREAKPOINTS,
  getBreakpoint,
  isSafelyInitializedWithLayout,
  isTabletOrSmallLaptopResolution
} from '../../../../../utils/commonHelper';
import {Ad} from '@dmm/react-common-components';
import {ThreeColumnListing} from '../ThreeColumnListing';
import PropTypes from 'prop-types';
import {useFeatureFlags} from '../../../../../context/hooks/useFeatureFlags';
import {Cookies} from 'react-cookie';
import {useTPPServices} from '../../../../../tppServices/tppDIHooks';
import {getMessages} from '../../../../../tppServices/translations/messages';
import {SponsoredBoatListings} from './SponsoredBoatListings';
import {ListingsWithoutCarrousel} from './ListingsWithoutCarrousel';
import {ListingsWithCarrousel} from './ListingsWithCarrousel';
import {
  buildBasicThreeColumnListingProps,
  buildEnhancedListingProps,
  buildOemListingProps,
  buildSponsoredListingProps, listingInNewTab,
  listingIsEnhanced,
  listingIsOemModel,
  MAX_DESKTOP_ADS
} from '../../../../../utils/propsBuilder/listingPropsBuilder';
import {EXPRESS_BRIDGE, getExpressBridgeService} from '../../../../../tppServices/expressBridgeService';
import AdSlot from '../../../../../components/Ads/AdSlot';

const SRPMobileAds = ({adParams, index}) => {
  return (<div className="mobile-box" key={`srp-mobile-${index}`}>
    <Ad {...adParams} />
  </div>);
};

SRPMobileAds.propTypes = {
  adParams: PropTypes.object,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export const ThreeColumnListings = ({
    sponsoredBoats,
    fuzzySponsoredBoats = [],
    listings,
    teaserRate,
    formatMessage: t,
    locale,
    openContactForm,
    adParams,
    tracking,
    setListingClick,
    userCurrency,
    cookies,
    isBranded,
    isLoading,
    newPageAdsConfig
  }) => {
  let desktopAdCount = 0;
  let tabletSmallLaptopAdCount = 0;
  let position = 0;
  let standardPosition = 0;
  const [device, setDevice] = useState('');
  const context = useContext(PortalConfigContext);
  const [shouldOpenInNewTab, setShouldOpenInNewTab] = useState(listingInNewTab(context, device));
  const {boatsConstants, tpp} = useTPPServices();
  const messages = getMessages();
  const expressBridge = getExpressBridgeService(tpp);
  expressBridge.shareWithExpress(EXPRESS_BRIDGE.LISTING_PARSABLE, true);
  const {
    LISTING_SPONSORED, LISTING_ENHANCED, LISTING_STANDARD, LISTING_MANUFACTURER, SPONSORED_CAROUSEL_LISTING, FIFTH_ROW_POSITION
  } = boatsConstants;
  const {featureFlagFuzzySponsored, featureFlagListingImageCarousel, featureFlagAdButler} = useFeatureFlags(cookies);
  const enableFuzzySponsoredSearch = !!context.supports?.enableFuzzySponsoredSearch && featureFlagFuzzySponsored;
  const enableListingImageCarousel = !!context.supports?.enableListingImageCarousel && featureFlagListingImageCarousel;
  const enableAdButler = !!context.supports?.adButler && featureFlagAdButler;
  const hideRequestInfoButton = !!context.supports?.hideRequestInfoButton;
  const enableRandomizedSponsoredBoatsSearch = !!context.supports?.enableRandomizedSponsoredBoatsSearch;
  const enableSponsoredListingsCarrousel = !!context.supports?.enableSponsoredListingsCarousel;
  const isSearchMobileBoxAds = !!context.supports?.isSearchMobileBoxAds;
  const currency = userCurrency;
  const renderWithCarrousel = enableSponsoredListingsCarrousel && fuzzySponsoredBoats?.length > 0;
  const isMobile = device === BREAKPOINTS.mobile;

  const updateDevice = (breakpoint) => {
    setDevice(breakpoint);
    setShouldOpenInNewTab(listingInNewTab(context, breakpoint));
  };

  // Set initial breakpoint for correct device layout
  useEffect(() => {
    const breakpoint = isTabletOrSmallLaptopResolution()
      ? BREAKPOINTS.tabletOrSmallLaptop
      : getBreakpoint();
    updateDevice(breakpoint);
  }, []);

  // Attach event listener to detect resize event
  useEffect(() => {
    const handleResize = () => {
      const breakpoint = isTabletOrSmallLaptopResolution()
        ? BREAKPOINTS.tabletOrSmallLaptop
        : getBreakpoint();
      updateDevice(breakpoint);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const supportsMonthlyPayment = useMemo(() => !!context.languages[locale]?.supports?.monthlyPayment, []);
  const msgsMonthlyPayment = useMemo(() => messages.boatLoansContent.monthlyPaymentTooltip, []);
  const isDesktop = useMemo(() => isSafelyInitializedWithLayout('desktop'), []);
  const translations = {t, messages, msgsMonthlyPayment};

  const preparePropsDependencies = (listing, position, currency = '') => {
    const priceProps = {currency, teaserRate, supportsMonthlyPayment};
    translations.priceSuffix = !isDesktop ? '*' : '';
    const propFlags = {enableListingImageCarousel, isBranded, shouldOpenInNewTab, hideRequestInfoButton};
    const makeDependencies = {device, tracking, locale, translations, position};
    const actions = {onClickRequestInfo, trackClick, onOpenContact};
    return {priceProps, propFlags, makeDependencies, actions};
  };

  const prepareListingProps = (listing, position, currency = '') => {
    const {priceProps, propFlags, makeDependencies, actions} = preparePropsDependencies(listing, position, currency);
    if (listingIsEnhanced(listing, LISTING_ENHANCED)) {
      makeDependencies.defaultType = LISTING_ENHANCED;
      const enhancedProps = buildEnhancedListingProps(listing, context, actions, makeDependencies, propFlags, priceProps);
      enhancedProps.targetBlank = shouldOpenInNewTab;
      return enhancedProps;
    } else if (listingIsOemModel(listing, LISTING_MANUFACTURER)) {
      makeDependencies.defaultType = LISTING_MANUFACTURER;
      const oemProps = buildOemListingProps(listing, context, actions, makeDependencies, propFlags, priceProps);
      oemProps.targetBlank = shouldOpenInNewTab;
      return oemProps;
    }
    const standardListing = buildBasicThreeColumnListingProps(listing, context, makeDependencies, propFlags, priceProps, actions);
    standardListing.type = LISTING_STANDARD;
    standardListing.targetBlank = shouldOpenInNewTab;
    return standardListing;
  };

  const appendThreeColListing = (listingArr, listing, position, standardPosition, index, currency = '', counter = 1, start) => {
    const itemKey = `${counter}-${index}`;
    const LAST_AD_POSITION = 28;
    if (
      (desktopAdCount < MAX_DESKTOP_ADS &&
      (index === 4 || (index - 4) % 5 === 0))
      || (position === LAST_AD_POSITION && desktopAdCount < MAX_DESKTOP_ADS + 1)
    ) {
      if (!enableAdButler) {
        let adSlot = adParams[`${'inlineBox'}${(desktopAdCount += 1)}${'Params'}`];
        listingArr.push(
          <span key={`desktop-ad-${itemKey}`} className="column-ads">
            <Ad {...adSlot} />
          </span>
        );
      } else {
        let count = desktopAdCount += 1;
        if ((!isBranded || count < 3)) {
          listingArr.push(
            <span key={`desktop-ad-${itemKey}`} className="column-ads">
            <AdSlot adParams={newPageAdsConfig} adSlot={`inline-box-${count}`} />
          </span>
          );
        }
      }

      position += 1;
    }
    const googlePosition = start;
    const listingProps = prepareListingProps(listing, position, currency);

    listingArr.push( <ThreeColumnListing key={itemKey} {...listingProps} position={ googlePosition } id={listing.id} /> );
    return listingArr;
  };

  const appendListingOnTabletOrSmallLaptop = (listingArr, listing, position, standardPosition, index, currency) => {
    if (index === 3 || index === 12) {

      if (!enableAdButler) {
        let adSlot1 = adParams[`${'inlineBox'}${(tabletSmallLaptopAdCount += 1)}${'Params'}`];
        let adSlot2 = adParams[`${'inlineBox'}${(tabletSmallLaptopAdCount += 1)}${'Params'}`];
        listingArr.push(
          <span key={`add-tablet-${index}`} className="column-ads tablet-ads">
            <Ad {...adSlot1} />
            <Ad {...adSlot2} />
          </span>
        );
      } else {
        let adSlotAdButler1 = tabletSmallLaptopAdCount += 1;
        let adSlotAdButler2 = tabletSmallLaptopAdCount += 1;
        if ((!isBranded || index === 3)) {
          listingArr.push(
            <span key={`add-tablet-${index}`} className="column-ads tablet-ads">
            <AdSlot adParams={newPageAdsConfig} adSlot={`inline-box-${adSlotAdButler1}`} />
            <AdSlot adParams={newPageAdsConfig} adSlot={`inline-box-${adSlotAdButler2}`} />
          </span>
          );
        }

      }
      position += 1;
    }
    const googlePosition = sponsoredBoats.length + index + 1;
    const props = prepareListingProps(listing, position, currency);
    listingArr.push( <ThreeColumnListing key={`col3-tablet-${index}`} {...props} position={ googlePosition } id={listing.id} /> );
    return listingArr;
  };

  const appendMobileAdToListingList = (listingArr, listing, index) => {
    let mobileBoxParams = null;
    if (index === 3) {
      mobileBoxParams = !enableAdButler ? adParams.mobileBox1Params : 'mobile-box-1';
    }

    if (index === 9) {
      mobileBoxParams = !enableAdButler ? adParams.mobileBox2Params : 'mobile-box-2';
    }

    if (index === 14 && !isBranded) {
      mobileBoxParams = !enableAdButler ? adParams.mobileBox3Params : 'mobile-box-3';
    }

    if (enableAdButler && index === 20 && !isBranded) {
      mobileBoxParams = 'mobile-box-4';
    }

    if (enableAdButler && index === 27 && !isBranded) {
      mobileBoxParams = 'mobile-box-5';
    }

    if (mobileBoxParams && isSearchMobileBoxAds) {
      if (!enableAdButler) {
        listingArr.push(<SRPMobileAds key={`appended-mobile-${index}`} adParams={mobileBoxParams} index={index}/>);
      } else {
        listingArr.push(
          <div className="mobile-box" key={`srp-mobile-${index}`}>
            <AdSlot adParams={newPageAdsConfig} adSlot={mobileBoxParams} />
          </div>
        );
      }
    }
    return listingArr;
  };

  const appendMobileAds = (listingArr, listing, sponsoredBoats, index, appendStickyAd ) => {
    const appendAd = appendStickyAd && !sponsoredBoats.length && index === 1;
    if (appendAd) {
      listingArr.push(
        <div key={`append-mobile-${index}`} className="sticky-mobile-ad">
          <Ad {...adParams.mobileLeaderboard1Params} />
        </div>
      );
    }
    appendMobileAdToListingList(listingArr, listing, position - 1);
  };

  const prepareFuzzySponsoredProps = (fuzzySponsoredBoat, position) => {
    position += 1;
    const { priceProps, propFlags, makeDependencies, actions } = preparePropsDependencies(fuzzySponsoredBoat, position, currency);
    propFlags.isExactMatchSponsored = false;
    makeDependencies.defaultType = LISTING_SPONSORED;
    makeDependencies.position = position;
    propFlags.debug = true;
    const fuzzyListingProps = buildSponsoredListingProps(fuzzySponsoredBoat, context, actions, makeDependencies, propFlags, priceProps);
    return fuzzyListingProps;
  };

  const createListingWithCarrouselChunk = (chunk, appendStickyAd, counter, start = 0) => {
    try {
      return chunk.reduce((listingArr, listing, index) => {
        position += 1;
        standardPosition += 1;
        const shifted = start + index + 1;
        if (device === BREAKPOINTS.tabletOrSmallLaptop) {
          return appendListingOnTabletOrSmallLaptop(listingArr, listing, position, standardPosition, index, currency);
        }
        if (device !== BREAKPOINTS.desktop) {
          appendMobileAds(listingArr, listing, sponsoredBoats, index, appendStickyAd);
        }
        return appendThreeColListing(listingArr, listing, position, standardPosition, index, currency, counter, shifted);
      }, []);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error in createListingWithCarrouselChunk', e);
      return [];
    }
  };

  const createListingsWithCarouselSponsoredChunk = (fuzzySponsoredBoatList, position, standardPosition, setListingClick, tracking, start) => {
    try {
      return fuzzySponsoredBoatList.map((fuzzySponsoredBoat, i) => {
        position += 1;
        const googlePosition = start + i + 1;
        const {
          priceProps,
          propFlags,
          makeDependencies,
          actions
        } = preparePropsDependencies(fuzzySponsoredBoatList, position, currency);
        propFlags.isExactMatchSponsored = false;
        makeDependencies.defaultType = SPONSORED_CAROUSEL_LISTING;
        makeDependencies.position = position;
        const fuzzyListingProps = buildSponsoredListingProps(fuzzySponsoredBoat, context, actions, makeDependencies, propFlags, priceProps);
        return (<ThreeColumnListing key={`fuzzy-carousel-${i}`} {...fuzzyListingProps} position={ googlePosition } targetBlank={true} id={fuzzySponsoredBoat.id} />);
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error in createListingsWithCarouselSponsoredChunk', e);
      return [];
    }
  };

  const onClickRequestInfo = (event, isExactMatchSponsored, listing) => {
    if (isExactMatchSponsored) {
      event.preventDefault();
      openContactForm(listing);
    } else {
      window.open(getBoatUrl(listing), shouldOpenInNewTab ? '_blank' : '_self');
    }
  };

  const trackClick = (listingId, listingType, region, make) => {
    return () => {
      setListingClick(listingId, listingType, region, make);
    };
  };

  const onOpenContact = (event, listing) => {
    event.preventDefault();
    openContactForm(listing);
  };
  const getSponsoredBoatsProps = () => {
    const priceProps = {currency, teaserRate, supportsMonthlyPayment};
    const propFlags = {enableListingImageCarousel, isBranded, shouldOpenInNewTab, hideRequestInfoButton};
    const makeDependencies = {device, tracking, locale, translations};
    const actions = {onClickRequestInfo, trackClick, onOpenContact};
    const type = LISTING_SPONSORED;
    return {
      sponsoredBoats, position, device, adParams, currency, tracking,
      priceProps, propFlags, makeDependencies, actions, type,
    };
  };

  const prepareListingsWithCarrouselChunks = () => {
    const sliceBreakPoint = device === BREAKPOINTS.tabletOrSmallLaptop ? 9 : 10;
    const firstSlice = listings.slice(0, sliceBreakPoint);
    const secondSlice = listings.slice(sliceBreakPoint);
    const start2Position = sponsoredBoats.length + sliceBreakPoint + fuzzySponsoredBoats.length;
    const topListingBlock = createListingWithCarrouselChunk(firstSlice, true, 1, sponsoredBoats.length);
    const bottomListingBlock = createListingWithCarrouselChunk(secondSlice, false, 2, start2Position);
    const startPosition = sliceBreakPoint + sponsoredBoats.length;
    const sponsoredListingCarouselBlock = createListingsWithCarouselSponsoredChunk(fuzzySponsoredBoats, position, standardPosition, setListingClick, tracking, startPosition);
    return {topListingBlock, bottomListingBlock, sponsoredListingCarouselBlock};
  };

  const getListingWithCarrouselProps = () => {
    const {topListingBlock, bottomListingBlock, sponsoredListingCarouselBlock} = prepareListingsWithCarrouselChunks();
    return {
      device,
      formatMessage: t,
      // first group of listings
      topListingBlock,
      // listings in the horizontal carousel
      sponsoredListingCarouselBlock,
      // second group of listings
      bottomListingBlock};
  };

  const prepareFuzzySponsoredListings = (start) => {
    try {
    const fuzzySponsoredListings = fuzzySponsoredBoats.reduce((listingArr, fuzzySponsoredBoat, i) => {
      position += 1;
      standardPosition += 1;
      const googlePosition = start + 1 + i;
      const inlineProps = prepareFuzzySponsoredProps(fuzzySponsoredBoat, position, standardPosition);
      listingArr.push(
        <ThreeColumnListing
          key={`fuzzy-sponsored-${i}`}
          {...inlineProps}
          position={ googlePosition }
          id={fuzzySponsoredBoat.id}
        />
      );
      return listingArr;
    }, []);
    return fuzzySponsoredListings;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error in prepareFuzzySponsoredListings', e);
      return [];
    }
  };

  const prepareListingsWithoutCarrousel = () => {
    try {
      let shift = sponsoredBoats.length;
      return listings.reduce((listingArr, listing, index) => {
        if (standardPosition === FIFTH_ROW_POSITION && (enableFuzzySponsoredSearch || enableRandomizedSponsoredBoatsSearch) && fuzzySponsoredBoats?.length > 0) {
          const start = sponsoredBoats.length + index;
          const innerFuzzySponsoredListings = prepareFuzzySponsoredListings(start);
          shift = sponsoredBoats.length + fuzzySponsoredBoats.length;
          listingArr = listingArr.concat(innerFuzzySponsoredListings);
        } else {
          position += 1;
          standardPosition += 1;
        }
        if (device === BREAKPOINTS.tabletOrSmallLaptop) {
          return appendListingOnTabletOrSmallLaptop( listingArr, listing, position, standardPosition, index, currency );
        }
        if (!sponsoredBoats.length && index === 1) {

          if (!enableAdButler) {
            listingArr.push(
              <div key={`mobile-ad-no-carousel-${index}`} className="sticky-mobile-ad">
                <Ad {...adParams.mobileLeaderboard1Params} />
              </div>
            );
          }
        }
        appendMobileAdToListingList(listingArr, listing, index);
        const shifted = shift + index + 1;
        return appendThreeColListing(listingArr, listing, position, standardPosition, index, currency, undefined, shifted);
      }, []);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error in prepareListingsWithoutCarrousel', e);
      return [];
    }
  };
  // We could move most of the listing generation logic to the Listings components and make use of with useMemo
  // maybe next time we clean up and refactor...
  return (
    <>
      {(!isMobile || (isMobile && !isLoading)) && <RenderThreeColumnListings
        sponsoredBoatsProp={getSponsoredBoatsProps}
        listingWithCarrouselProps={getListingWithCarrouselProps}
        listingsWithoutCarrousel={prepareListingsWithoutCarrousel}
        renderWithCarrousel={renderWithCarrousel}
      />}
    </>
  );
};

ThreeColumnListings.propTypes = {
  sponsoredBoats: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fuzzySponsoredBoats: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  listings: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  teaserRate: PropTypes.number,
  formatMessage: PropTypes.func,
  locale: PropTypes.string,
  openContactForm: PropTypes.func,
  adParams: PropTypes.object,
  setListingClick: PropTypes.func,
  tracking: PropTypes.shape({
    region: PropTypes.string,
    page: PropTypes.string,
    pageSize: PropTypes.string,
    setProductImpression: PropTypes.func,
    registerProductImpressions: PropTypes.func
  }),
  userCurrency: PropTypes.string,
  cookies: PropTypes.instanceOf(Cookies),
  isBranded: PropTypes.bool,
  Carousel: PropTypes.object,
  isLoading: PropTypes.bool,
  newPageAdsConfig: PropTypes.object
};

const RenderThreeColumnListings = ({ sponsoredBoatsProp, listingWithCarrouselProps, listingsWithoutCarrousel, renderWithCarrousel }) => {
  return (
    <>
      <SponsoredBoatListings {...sponsoredBoatsProp()} />
      {!renderWithCarrousel && <ListingsWithoutCarrousel listings={listingsWithoutCarrousel()}/>}
      {renderWithCarrousel && <ListingsWithCarrousel {...listingWithCarrouselProps()} />}
    </>
  );
};

RenderThreeColumnListings.propTypes = {
  sponsoredBoatsProp: PropTypes.func,
  listingWithCarrouselProps: PropTypes.func,
  listingsWithoutCarrousel: PropTypes.func,
  renderWithCarrousel: PropTypes.bool,
};
